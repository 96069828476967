var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-6 offset-3" }, [
      _c("h2", { staticClass: "text-center m-auto" }, [
        _vm._v(_vm._s(_vm.$t("views.constants.registrationComplete")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }